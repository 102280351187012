import React from "react"
import { Link } from "gatsby"

import Img from 'gatsby-image'

const PostLink = ({ post }) => (
  <article className="card ">
    <Link to={post.frontmatter.path}>
      {JSON.stringify(post.frontmatter.thumbnail.src)}
      {!!post.frontmatter.thumbnail && (
        <Img fluid={post.frontmatter.thumbnail.childImageSharp.fluid} style={{maxHeight: 200}}/>
      )}
    </Link>
    <header>
      <h2 className="post-title">
        <Link to={post.frontmatter.path} className="post-link">
          {post.frontmatter.title}
        </Link>
      </h2>
      <div className="post-meta">{post.frontmatter.date}</div>
    </header>
  </article>
)
export default PostLink
